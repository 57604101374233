import notification, { IconType } from "ant-design-vue/lib/notification";
import { i18nTranslate } from "@/plugins/i18n";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";

export const makeToast = (
  type: IconType,
  title: string,
  message: string,
  duration?: number,
  key?: string
): void => {
  if (isEmpty(message)) {
    return;
  }

  /// In case a malformed message is passed on the toast just show a default error
  if (!isString(message)) {
    return makeDefaultErrorToast();
  }

  // Note: Set timeout here is needed so we don't trigger an infinite loop of updates
  setTimeout(() => {
    notification[type]({
      message: title,
      description: message,
      duration: duration ?? 10,

      /**
       * Setting the message as the key will prevent the UI from
       * displaying multiple toasts with just the same messages
       */
      key: key ?? message,
    });
  }, 500);
};

export const makeDefaultErrorToast = () => {
  makeToast(
    "error",
    i18nTranslate("Error"),
    i18nTranslate("Something went wrong. Please try again later.")
  );
};
