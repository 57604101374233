import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { authenticateViaAvataradaGql } from "@/api/authenticate/authenticateViaAvatarada";
import {
  AuthenticateViaAvatarada,
  AuthenticateViaAvataradaVariables,
  AuthenticateViaAvatarada_authenticateViaAvatarada_Authentication,
} from "@/api/authenticate/__generated__/AuthenticateViaAvatarada";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { useStore } from "vuex";
import { logoutGql } from "@/api/authenticate/logout";
import {
  Logout,
  Logout_logout_GenericSuccess,
} from "@/api/authenticate/__generated__/Logout";
import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

export const useAuthenticateViaAvatarada = () => {
  const { t } = useI18n();
  const store = useStore();
  const router = useRouter();

  /**
   * ### LOGIN WITH AVATARADA ###
   */
  const {
    mutate: authenticateViaAvataradaMutation,
    loading: authenticateViaAvataradaLoading,
  } = useCustomMutation<
    AuthenticateViaAvatarada,
    AuthenticateViaAvataradaVariables
  >(authenticateViaAvataradaGql);
  const authenticateViaAvatarada = async ({
    authorizationCode,
    redirectUri,
  }) => {
    const authenticateViaAvataradaResponse =
      await authenticateViaAvataradaMutation({
        input: {
          authorizationCode,
          redirectUri,
        },
      });
    const parsedResponse =
      parseGqlResponse<AuthenticateViaAvatarada_authenticateViaAvatarada_Authentication>(
        "Authentication",
        authenticateViaAvataradaResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("authenticateViaAvatarada:parsedResponse", parsedResponse);

    const authToken = parsedResponse?.data?.authToken;
    const user = parsedResponse?.data?.user;

    // On success
    if (authToken) {
      await store.dispatch("authUpdate", {
        isAuthenticated: true,
        token: authToken.token,
        expiry: authToken.expiry,
        userId: user?.id,
      });

      await store.dispatch("loginSuccess", {
        id: user?.id,
        name: user?.name,
        username: user?.username,
        avatar: user?.avatarUrl,
        isDelegated: user?.isDelegated,
      });
    }

    // On error
    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !authenticateViaAvataradaResponse
    ) {
      throw new Error("Failed to authenticate via Avatarada");
    }

    return parsedResponse.data;
  };
  /**
   * ### LOGIN WITH AVATARADA END ###
   */

  /**
   * ### LOGOUT ###
   */
  const { mutate: logoutMutation, loading: logoutLoading } =
    useCustomMutation<Logout>(logoutGql);
  const handleLogout = async () => {
    const logoutResponse = await logoutMutation();

    const parsedResponse = parseGqlResponse<Logout_logout_GenericSuccess>(
      "GenericSuccess",
      logoutResponse,
      apiErrorCodes.INTERNAL_ERROR
    );
    console.log("logoutResponse: parsedResponse", parsedResponse);

    /**
     * TODO: Handle other errors
     */
    if (parsedResponse.data?.success) {
      message.info(t("Successfully Logout"));
      await store.dispatch("logoutSuccess");
      await router.push({ name: routeNames.home });
      window.location.reload();
    }
  };
  /**
   * ### LOGOUT END ###
   */

  return {
    authenticateViaAvatarada,
    authenticateViaAvataradaLoading,
    handleLogout,
    logoutLoading,
  };
};
