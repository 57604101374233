import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-footer-banner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 2,
      class: "layout-footer-banner__text m-0"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Join the community.")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, {
      level: 2,
      class: "layout-footer-banner__text m-0"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Change the world for the better.")), 1)
      ]),
      _: 1
    })
  ]))
}