import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "authentication-button" }
const _hoisted_2 = {
  key: 0,
  class: "authentication-button__logout flex items-center cursor-pointer gap-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_LoginWithAvatarada = _resolveComponent("LoginWithAvatarada")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRedirectToProfile && _ctx.handleRedirectToProfile(...args)))
          }, [
            _createVNode(_component_AppIcon, {
              name: "profile",
              class: "authentication-button__profile-icon mr-1"
            }),
            _createVNode(_component_a_typography_text, { class: "authentication-button__profile-name text-sm mt-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userName), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_a_button, {
            class: "p-0 authentication-button__logout-btn",
            type: "text",
            loading: _ctx.logoutLoading,
            onClick: _ctx.handleLogout
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Log out")), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]))
      : (_openBlock(), _createBlock(_component_LoginWithAvatarada, { key: 1 }))
  ]))
}