import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutHeader = _resolveComponent("LayoutHeader")!
  const _component_MobileDrawer = _resolveComponent("MobileDrawer")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_LayoutFooter = _resolveComponent("LayoutFooter")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "non-authenticated-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutHeader, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
        onOnDrawerOpen: _ctx.toggleMobileDrawer,
        onOnDrawerClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleMobileDrawer(false)))
      }, null, 8, ["visible", "onOnDrawerOpen"]),
      _createVNode(_component_MobileDrawer, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
        onOnDrawerToggle: _ctx.toggleMobileDrawer
      }, null, 8, ["visible", "onOnDrawerToggle"]),
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_LayoutFooter)
    ]),
    _: 3
  }))
}