import sharedRoutePaths from "@/shared/router/routePaths";

export default {
  pools: "/pools",
  profile: "/profile",
  profileIntro: "/profile/intro/:pool_hash",
  profileNews: "/profile/news/:pool_hash",
  causes: "/causes",
  team: "/team",
  pool: "/pool/:pool_hash",
  block: "/block/:block_hash",
  delegation: "/delegation/:delegation_address",
  ...sharedRoutePaths,
};
