import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/web/store";

export type AuthState = {
  isAuthenticated: boolean;
  token: string;
  expiry: string;
  userId: string | number;
};

export type AuthPayload = {
  isAuthenticated: boolean;
  token: string;
  expiry: string;
  userId: string | number;
};

const authInitialState = {
  isAuthenticated: false,
  token: "",
  expiry: "",
  userId: "",
};

const authUpdateActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>("authUpdate", (state, payload) => {
  if (payload.isAuthenticated) state.isAuthenticated = payload.isAuthenticated;
  if (payload.token) state.token = payload.token;
  if (payload.expiry) state.expiry = payload.expiry;
  if (payload.userId) state.userId = payload.userId;
});

const authResetActionMutation = resetStateActionMutationFactory<
  AuthState,
  RootState
>("logoutSuccess", authInitialState);

export const authStore = {
  state: {
    ...authInitialState,
  },
  actions: {
    ...authResetActionMutation.actions,
    ...authUpdateActionMutation.actions,
  },
  mutations: {
    ...authResetActionMutation.mutations,
    ...authUpdateActionMutation.mutations,
  },
};
