// import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
// import { logsApiGql } from "@/api/logging/loggingEndpoint";
// import { LogLevel } from "../../../__generated__/globalTypes";

// use logging types from server, but it doesn't exist now
enum TemporaryLogLevel {
  ERROR = "ERROR",
  FATAL = "FATAL",
  INFO = "INFO",
  WARNING = "WARNING",
}

// temporary noop function
const logsApiGqlMutation = { mutate: (value) => value };

export default function useLogging() {
  // const logsApiGqlMutation = useCustomMutation(logsApiGql);

  const submitToApi = async (
    logLevel = TemporaryLogLevel.INFO,
    message: string,
    moreDetailsObject = {}
  ) => {
    const createLogResponse = await logsApiGqlMutation.mutate({
      input: {
        logLevel,
        message: message,
        context: JSON.stringify(moreDetailsObject),
      },
    });

    console.log("createLogResponse", createLogResponse);
    return createLogResponse;
  };

  const submitInfoToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(TemporaryLogLevel.INFO, message, moreDetailsObject);
  };

  const submitWarningToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(TemporaryLogLevel.WARNING, message, moreDetailsObject);
  };

  const submitErrorToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(TemporaryLogLevel.ERROR, message, moreDetailsObject);
  };

  const submitFatalToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(TemporaryLogLevel.FATAL, message, moreDetailsObject);
  };

  return {
    submitToApi,
    submitInfoToApi,
    submitWarningToApi,
    submitErrorToApi,
    submitFatalToApi,
  };
}
