import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-col epoch-deadline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_progress = _resolveComponent("a-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentEpochLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          class: "epoch-deadline__details--loading",
          active: "",
          paragraph: false
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_a_typography_text, {
            type: "secondary",
            class: "epoch-deadline__details"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("E{epoch} Left: {deadline}", {
            epoch: _ctx.currentEpoch?.epoch?.number,
            deadline: _ctx.currentEpoch?.estimatedExpiryTimestamp
              ? _ctx.formatDistanceToNowStrict(
                  _ctx.autoDateObject(_ctx.currentEpoch?.estimatedExpiryTimestamp)
                )
              : "",
          })), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_text, {
            type: "secondary",
            class: "epoch-deadline__details-mobile"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("E{epoch}", {
            epoch: _ctx.currentEpoch?.epoch?.number,
          })), 1)
            ]),
            _: 1
          })
        ], 64)),
    (_ctx.currentEpoch?.totalBlocks && _ctx.currentEpoch.slot)
      ? (_openBlock(), _createBlock(_component_a_progress, {
          key: 2,
          class: "epoch-deadline__progress",
          percent: Number(_ctx.currentEpoch?.totalBlocks) / Number(_ctx.currentEpoch.slot),
          "show-info": false
        }, null, 8, ["percent"]))
      : _createCommentVNode("", true)
  ]))
}