
import LayoutAuthenticated from "@/shared/components/Layouts/LayoutAuthenticated.vue";
import LayoutNonAuthenticated from "@/shared/components/Layouts/LayoutNonAuthenticated.vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VErrorBoundary from "vue-error-boundary";
import { useStore } from "vuex";

export default defineComponent({
  components: { LayoutAuthenticated, LayoutNonAuthenticated, VErrorBoundary },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    return {
      t,
      isAuthenticated,
    };
  },
});
