import "./shared/utils/logger";
import i18n from "@/plugins/i18n";
import { useAntDesignComponents } from "@/plugins/antDesignComponents";
import app from "@/entryApp";
import "@/less/index.less";
import { useFormLoadingDirective } from "./shared/directives/formLoadingDirective";
import { useVueAuthenticatePlugin } from "./plugins/vueAuthenticatePlugin";

useFormLoadingDirective(app);
useVueAuthenticatePlugin(app);

/**
 * Setup plugins for whole site
 */
useAntDesignComponents(app).use(i18n).mount("#app");
