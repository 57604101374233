import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVisible) = $event)),
    placement: "left",
    closable: false,
    "body-style": { height: 'calc(100% - 72px)', marginTop: '56px' },
    class: "mobile-drawer"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ThemeSwitcher, { class: "mobile-drawer__theme-switcher" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRoutes, (link) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: link.name,
          to: link,
          custom: ""
        }, {
          default: _withCtx(({ navigate, isExactActive }) => [
            _createVNode(_component_a_button, {
              type: "text",
              class: _normalizeClass(["link lighter-text text-left", { active: isExactActive && (link.hash ?? '') === _ctx.routeHash }]),
              onClick: 
          () => {
            navigate();
            _ctx.$emit('on-drawer-toggle');
          }
        
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(link.label), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick"])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128)),
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Divider, { class: "my-4" }),
            _createVNode(_component_a_button, {
              class: "mobile-drawer__logout-btn link",
              type: "text",
              loading: _ctx.logoutLoading,
              onClick: _ctx.handleLogout
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Log out")), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "body-style"]))
}