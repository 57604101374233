import sharedRouteNames from "@/shared/router/routeNames";

export default {
  pools: "pools",
  profile: "profile",
  profileIntro: "profile-intro",
  profileNews: "profile-news",
  causes: "causes",
  team: "team",
  pool: "pool",
  block: "block",
  delegation: "delegation",
  ...sharedRouteNames,
};
