export const apiErrorCodes = {
  AUTHENTICATION_ERROR: 101001,
  TWO_FACTOR_AUTH: 102013,
  NO_FILES_FOUND: 103002,
  NO_DOCUMENT_FOUND: 103001,
  INTERNAL_ERROR: 100001,
  INVALID_INPUT: 102001,
  NON_WHITE_LISTED: 21608,
  PO_DOES_NOT_EXIST: 104014,
};

export const regex = {
  email:
    "^(([^<>()\\[\\]\\.,;:\\s@\\']+(\\.[^<>()\\[\\]\\.,;:\\s@']+)*)|('.+'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
  alphanumericWithSpace: "^[a-zA-Z0-9\\s]+$",
};
