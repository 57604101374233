import { setContext } from "@apollo/client/link/context";
import store from "@/web/store";

export const getAuthToken = () => {
  const savedUserInfo = store?.state?.auth;

  if (savedUserInfo?.token) {
    return `Token ${savedUserInfo.token}`;
  }

  return "";
};

export const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAuthToken(),
    },
  };
});
