import { format } from "date-fns";
import formatRelative from "date-fns/formatRelative";
import enUS from "date-fns/locale/en-US";
import { i18nTranslate } from "@/plugins/i18n";

export const dateFormats = {
  default: "MM/DD/YYYY HH:mm:ss a",
  defaultDateWithNoTime: "MM/DD/YYYY",
  defaultDateWithNoTimePretty: "PP", // Apr 29, 1453
  dateTime: "MM/DD/YYYY, hh:mm:ss a",
  dateTimeShort: "dd.MM.yyyy, H:mm",
  dateTimePretty: "PPp", // Sep 12, 2022, 5:30 PM
  time: "hh:mm:ss a",
  shortTime: "hh:mm a",
  yearFormat: "YYYY",
};

// using i18nTranslate causes an error on unescaped latin alphabet character
const formatRelativeLocale = {
  // When using a string, we need to wrap words with ''
  lastWeek: `'${i18nTranslate("last week")}'`,
  yesterday: `'${i18nTranslate("yesterday")}'`,
  today: `'${i18nTranslate("today")}'`,
  other: `'${i18nTranslate("on")}' LLL Y`, // LLL = Jan, Feb, etc... Y=2022 e.g. on May 2022
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const autoDateObject = (strOrDate) => new Date(strOrDate);

export const formatDate = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.defaultDateWithNoTime);

export const formatDatePretty = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.defaultDateWithNoTimePretty);

export const formatTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.time);

export const formatShortTime = (timestampStr: string | Date) =>
  format(new Date(timestampStr), dateFormats.shortTime);

export const formatDateTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.dateTime);

export const formatDateTimePretty = (timestampStr) =>
  format(autoDateObject(timestampStr), dateFormats.dateTimePretty);

/**
 * Locale date-time short format
 *
 * @param date
 * @returns string locale short date-time
 */
export const formatLocaleDateTimeShort = (date: string | Date) => {
  /**
   * Note: this function is equivalent to (as of writing not working because of TS version)
   * date.toLocaleString("ru-RU", { dateStyle: "short", timeStyle: "short" });
   */
  return format(autoDateObject(date), dateFormats.dateTimeShort);
};

export const dateFromNowPretty = (dateToFormat: Date | number) =>
  formatRelative(dateToFormat, new Date(), { locale });

// GraphQL Date Formatters
export const formatGraphQLDate = (timestampStr: string) =>
  format(new Date(timestampStr), "yyyy-MM-dd");
