import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppIcon, _mergeProps({
      name: _ctx.isDarkTheme ? 'logoWhite' : 'logo',
      class: "brand-icon"
    }, _ctx.$attrs), null, 16, ["name"]),
    _createVNode(_component_AppIcon, _mergeProps({
      name: _ctx.isDarkTheme ? 'logoAltWhite' : 'logoAlt',
      class: "brand-icon-mobile"
    }, _ctx.$attrs), null, 16, ["name"])
  ], 64))
}