
import { defineComponent, computed } from "vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import { footerRoutes } from "@/web/router";
import routeNames from "@/shared/router/routeNames";
import webRouteNames from "@/web/router/routeNames";
import LayoutFooterBanner from "@/shared/components/Layouts/LayoutFooterBanner.vue";
import { useStore } from "vuex";
import AuthenticationButton from "../AuthenticationButton.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BrandIcon,
    LayoutFooterBanner,
    AuthenticationButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    /**
     * check if current route is profile, hide the footer banner on profile pages
     */
    const isFooterBannerShown = computed(
      () => router?.currentRoute?.value?.name !== webRouteNames.profile
    );

    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    return { routeNames, footerRoutes, isAuthenticated, isFooterBannerShown };
  },
});
