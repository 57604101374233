import { ActionContext } from "vuex";

type ActionMutationResponseType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: { [key: string]: (context: any, data: any) => void };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutations: { [key: string]: (state: any, data: any) => void };
};

/// Use this for simple action and mutation where the action usually just calls the
/// related mutation.
export const actionMutationFactory = <StateType, DataType, RootState>(
  actionName: string,
  mutationFunction: (state: StateType, data: DataType) => void
): ActionMutationResponseType => {
  return {
    actions: {
      [actionName]: (
        context: ActionContext<StateType, RootState>,
        data: DataType
      ) => {
        context.commit(actionName, data);
      },
    },
    mutations: {
      [actionName]: mutationFunction,
    },
  };
};

/// Use this to easily add a reset function to a store. This should allow you to
/// clear it on demand. i.e. clear the state on component destroy lifecycle
export const resetStateActionMutationFactory = <StateType, RootState>(
  actionName: string,
  initialState: StateType
): ActionMutationResponseType =>
  actionMutationFactory<StateType, null, RootState>(
    actionName,
    (state: StateType): void => {
      Object.keys(initialState).forEach((key) => {
        const initialValue = initialState[key];
        if (state[key] === initialValue) return;
        if (typeof initialValue === "object") {
          Object.assign(state[key], initialValue);
        } else {
          state[key] = initialValue;
        }
      });
    }
  );
