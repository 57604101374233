import gql from "graphql-tag";

export const currentEpochGql = gql`
  query CurrentEpoch {
    currentEpoch {
      ... on CurrentEpochDetail {
        epoch {
          id
          number
        }
        slot
        poolboostPoolCount
        currentBlockNumber
        totalBlocks
        estimatedExpiryTimestamp
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
