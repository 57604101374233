import { computed } from "vue";
import { currentEpochGql } from "@/api/epoch/currentEpoch";
import {
  CurrentEpoch,
  CurrentEpoch_currentEpoch_CurrentEpochDetail,
} from "@/api/epoch/__generated__/CurrentEpoch";
import { useQuery } from "@vue/apollo-composable";
import isEmpty from "lodash/isEmpty";
import { parseGqlResponse } from "../utils/graphql/responseParser";

export const useCurrentEpoch = () => {
  const { result: currentEpochResult, loading: currentEpochLoading } =
    useQuery<CurrentEpoch>(currentEpochGql);

  const currentEpoch = computed(() => {
    const parsedResponse =
      parseGqlResponse<CurrentEpoch_currentEpoch_CurrentEpochDetail>(
        "CurrentEpochDetail",
        currentEpochResult.value
      );

    console.log("currentEpoch: parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !parsedResponse) {
      console.error("currentEpoch error", { parsedResponse });
    }

    return parsedResponse.data;
  });

  return { currentEpochLoading, currentEpoch };
};
