import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "layout-header__links-container" }
const _hoisted_2 = { class: "layout-header__links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EpochDeadline = _resolveComponent("EpochDeadline")!
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!
  const _component_AuthenticationButton = _resolveComponent("AuthenticationButton")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "layout-header flex items-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.routeNames.home },
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-drawer-close')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BrandIcon, { class: "layout-header__brand" })
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRoutes, (link) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: link.name,
              to: link,
              custom: ""
            }, {
              default: _withCtx(({ navigate, href, isExactActive }) => [
                _createVNode(_component_a_button, {
                  href: href,
                  type: link.hash || !isExactActive ? 'link' : 'default',
                  class: _normalizeClass(["mr-4", { active: isExactActive }]),
                  size: "small",
                  onClick: navigate
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.label), 1)
                  ]),
                  _: 2
                }, 1032, ["href", "type", "class", "onClick"])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ]),
      _createVNode(_component_EpochDeadline, { class: "layout-header__epoch-details" }),
      _createVNode(_component_ThemeSwitcher, { class: "layout-header__theme-switcher" }),
      _createVNode(_component_AuthenticationButton, {
        class: "layout-header__authentication-button",
        onOnDrawerClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-drawer-close')))
      }),
      _createElementVNode("span", {
        class: "layout-header__drawer-toggler cursor-pointer ml-5",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-drawer-open')))
      }, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.visible)
              ? (_openBlock(), _createBlock(_component_CloseOutlined, { key: 0 }))
              : (_openBlock(), _createBlock(_component_MenuOutlined, { key: 1 }))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}