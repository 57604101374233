
import { defineComponent } from "vue";
import LayoutNonAuthenticated from "./LayoutNonAuthenticated.vue";

export default defineComponent({
  components: { LayoutNonAuthenticated },
  setup() {
    return {};
  },
});
