
import { computed, defineComponent } from "vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import ThemeSwitcher from "@/shared/components/ThemeSwitcher.vue";
import EpochDeadline from "@/shared/components/EpochDeadline.vue";
import { headerRoutes } from "@/web/router";
import routeNames from "@/shared/router/routeNames";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import AuthenticationButton from "@/shared/components/AuthenticationButton.vue";

export default defineComponent({
  components: {
    BrandIcon,
    ThemeSwitcher,
    EpochDeadline,
    AuthenticationButton,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["on-drawer-open", "on-drawer-close"],
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    return {
      t,
      routeNames,
      headerRoutes,
      isAuthenticated,
    };
  },
});
