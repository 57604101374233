import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes from "@/shared/router";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import store from "../store";
import NProgress from "nprogress";
import { i18nTranslate } from "@/plugins/i18n";

/**
 * Note: Add `web` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. webHome)
 */
const webRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.home,
    name: routeNames.home,
    component: () =>
      import(/* webpackChunkName: "webHome" */ "../views/Home/Home.vue"),
  },
  {
    path: routePaths.pools,
    name: routeNames.pools,
    component: () =>
      import(/* webpackChunkName: "webPools" */ "../views/Pools.vue"),
  },
  {
    path: routePaths.pool,
    name: routeNames.pool,
    component: () =>
      import(/* webpackChunkName: "webPool" */ "../views/Pool/Pool.vue"),
  },
  {
    path: routePaths.profile,
    name: routeNames.profile,
    component: () =>
      import(
        /* webpackChunkName: "webProfile" */ "../views/Profile/Profile.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.profileIntro,
    name: routeNames.profileIntro,
    component: () =>
      import(
        /* webpackChunkName: "webProfile" */ "../views/Profile/ProfileTabs/MyPools/MyPoolsIntro.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.profileNews,
    name: routeNames.profileNews,
    component: () =>
      import(
        /* webpackChunkName: "webProfile" */ "../views/Profile/ProfileTabs/MyPools/MyPoolsNews.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.causes,
    name: routeNames.causes,
    component: () =>
      import(/* webpackChunkName: "webCauses" */ "../views/Causes.vue"),
  },
  {
    path: routePaths.team,
    name: routeNames.team,
    component: () =>
      import(/* webpackChunkName: "webTeam" */ "../views/Team.vue"),
  },
  {
    path: routePaths.block,
    name: routeNames.block,
    component: () =>
      import(/* webpackChunkName: "webBlock" */ "../views/Block.vue"),
  },
  {
    path: routePaths.delegation,
    name: routeNames.delegation,
    component: () =>
      import(/* webpackChunkName: "webDelegation" */ "../views/Delegation.vue"),
  },
  ...sharedRoutes,
];

export const headerRoutes = [
  {
    label: i18nTranslate("Pools"),
    name: routeNames.pools,
  },
  {
    label: i18nTranslate("Missions"),
    name: routeNames.home,
    hash: "#missions",
  },
  {
    label: i18nTranslate("How it works"),
    name: routeNames.home,
    hash: "#how-it-works",
  },
];

export const footerRoutes = [
  {
    label: i18nTranslate("Pools"),
    name: routeNames.pools,
  },
  {
    label: i18nTranslate("Causes"),
    name: routeNames.causes,
  },
  {
    label: i18nTranslate("How it works"),
    name: routeNames.home,
    hash: "#how-it-works",
  },
  {
    label: i18nTranslate("Team"),
    name: routeNames.team,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // @see https://stackoverflow.com/a/57212309 this will ensure user will see top most of the
  // screen when navigating to different pages
  routes: webRoutes,
  scrollBehavior(to) {
    /**
     * Scroll to hash if it's set, this is the default browser behavior
     * Else just scroll to the main app location
     */
    return {
      el: to.hash || "#app",
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  // Start the route progress bar.
  NProgress.start();
  const isAuthenticated = store.state.auth.isAuthenticated;

  // const meta = to.meta;

  if (to.meta.requiresAuth && !isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to home page (since there's no login page).
    return next({ name: routeNames.home });
  }

  // If going to a page that does not require authentication
  // if (!to.matched.some((record) => record.meta.requiresAuth)) {
  //   // Some pages (like Login page) should not be accessed if user is logged in
  //   if (isAuthenticated && !to.meta.requiresAuth) {
  //     return next({ name: routeNames.home });
  //   }

  //   return next();
  // }

  next();
});

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
