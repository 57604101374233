import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "layout-footer__navigation flex" }
const _hoisted_2 = { class: "layout-footer__navigation-links" }
const _hoisted_3 = { class: "brand-container" }
const _hoisted_4 = { class: "links-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutFooterBanner = _resolveComponent("LayoutFooterBanner")!
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_AuthenticationButton = _resolveComponent("AuthenticationButton")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!

  return (_openBlock(), _createBlock(_component_a_layout_footer, { class: "layout-footer" }, {
    default: _withCtx(() => [
      (_ctx.isFooterBannerShown)
        ? (_openBlock(), _createBlock(_component_LayoutFooterBanner, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: { name: _ctx.routeNames.home },
              class: "brand"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BrandIcon, { class: "mr-12" })
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("span", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerRoutes, (link) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: link.name,
                to: link,
                custom: ""
              }, {
                default: _withCtx(({ navigate, href, isExactActive }) => [
                  _createVNode(_component_a_button, {
                    href: href,
                    type: link.hash || !isExactActive ? 'text' : 'default',
                    class: _normalizeClass(["lighter-text", { active: isExactActive }]),
                    size: "small",
                    onClick: navigate
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(link.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["href", "type", "class", "onClick"])
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ]),
        _createVNode(_component_AuthenticationButton, { class: "layout-footer__navigation-actions" })
      ])
    ]),
    _: 1
  }))
}