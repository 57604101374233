
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { useAuthenticateViaAvatarada } from "@/shared/composables/api/useAuthentication";
import LoginWithAvatarada from "@/shared/components/LoginWithAvatarada.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  components: { LoginWithAvatarada, AppIcon },
  emits: ["on-drawer-close"],
  setup(_, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    const userName = computed(() => {
      const userInfo = store.state.userInfo;
      return userInfo?.name || maskWalletAddress(userInfo?.username) || "";
    });

    const handleRedirectToProfile = () => {
      router.push({ name: routeNames.profile });
      emit("on-drawer-close");
    };

    const { handleLogout, logoutLoading } = useAuthenticateViaAvatarada();

    return {
      t,
      isAuthenticated,
      userName,
      handleRedirectToProfile,
      handleLogout,
      logoutLoading,
    };
  },
});
