import { App } from "@vue/runtime-core";
import VueAuthenticate from "vue-authenticate-2";
import axios from "axios";
import map from "lodash/map";
import { config } from "@/shared/utils/config";

export const providers = {
  ["AVATARADA"]: {
    name: "AVATARADA",
    label: "Avatarada",
    authorizationEndpoint: config.authAvataradaOauthUrl,
    oauthType: "2.0",
    clientId: config.authAvataradaClientId,
    redirectUri: config.authAvataradaRedirectUri,
    responseType: "code",
    popupOptions: { width: 500, height: 640 },
  },
};

/**
 * Return supported platforms in name-label pair
 */
export const supportedPlatforms = map(providers, (item, key) => ({
  name: key,
  label: item?.label,
  value: "", // this is the value of the platform, e.g. username
}));

export const useVueAuthenticatePlugin = (app: App): App => {
  return app.use(VueAuthenticate, {
    baseUrl: config.authBaseUrl, // Your API domain
    axios: axios.create({}),
    providers,
  });
};
