/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode } from "graphql";
import {
  useMutation as useMutationRaw,
  UseMutationOptions,
  UseMutationReturn,
} from "@vue/apollo-composable";
import { OperationVariables, TypedDocumentNode } from "@apollo/client/core";
import { Ref } from "vue-demi";

/**
 * This type declarations are only copied from @vue/apollo-composable, which are not exported
 */
declare type ReactiveFunction<TParam> = () => TParam;
declare type DocumentParameter<TResult, TVariables> =
  | DocumentNode
  | Ref<DocumentNode>
  | ReactiveFunction<DocumentNode>
  | TypedDocumentNode<TResult, TVariables>
  | Ref<TypedDocumentNode<TResult, TVariables>>
  | ReactiveFunction<TypedDocumentNode<TResult, TVariables>>;
declare type OptionsParameter<TResult, TVariables> =
  | UseMutationOptions<TResult, TVariables>
  | Ref<UseMutationOptions<TResult, TVariables>>
  | ReactiveFunction<UseMutationOptions<TResult, TVariables>>;

/**
 * Updated useMutation with default throws: "never" option
 */
function useMutation<
  TResult = any,
  TVariables extends OperationVariables = OperationVariables
>(
  document: DocumentParameter<TResult, TVariables>,
  options?: OptionsParameter<TResult, TVariables>
): UseMutationReturn<TResult, TVariables> {
  return useMutationRaw(document, { throws: "never", ...options });
}

export const useCustomMutation = useMutation;

export default useMutation;
