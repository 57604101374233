
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import logo from "@/assets/logo.svg";
import logoWhite from "@/assets/logo-white.svg";
import logoAlt from "@/assets/logo-alt.svg";
import logoAltWhite from "@/assets/logo-alt-white.svg";
import sun from "@/assets/icons/sun.svg";
import moon from "@/assets/icons/moon.svg";
import reward from "@/assets/icons/reward.svg";
import voteStake from "@/assets/icons/vote_stake.svg";
import news from "@/assets/icons/news.svg";
import editPencil from "@/assets/icons/edit_pencil.svg";
import profile from "@/assets/icons/profile.svg";
import trash from "@/assets/icons/trash.svg";

export const getAppIcon = (iconName: string) => {
  return {
    logo,
    logoWhite,
    sun,
    moon,
    reward,
    editPencil,
    news,
    voteStake,
    profile,
    trash,
    logoAlt,
    logoAltWhite,
  }[iconName];
};

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getAppIcon },
});
