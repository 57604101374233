import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/web/store";
import merge from "lodash/merge";
export type User = {
  id: string;
  username: string;
  name: string;
  avatar: string | null;
  isDelegated: boolean;
};

export type UserInfoState = Partial<User>;

const userInfoInitialState = {
  id: "",
  username: "",
  name: "",
  avatar: "",
  isDelegated: false,
};

export type UserInfoPayload = User;

const loginSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("loginSuccess", (state, payload) => {
  state = merge(state, payload);
});

const userUpdateActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("userUpdate", (state, payload) => {
  /**
   * merge state value with the new one from payload
   * in this case, payload value is prioritize
   */
  state = merge(state, payload);
});

const logoutSuccessActionMutation = resetStateActionMutationFactory<
  UserInfoState,
  RootState
>("logoutSuccess", userInfoInitialState);

export const userInfo = {
  state: {
    ...userInfoInitialState,
  },
  actions: {
    ...loginSuccessActionMutation.actions,
    ...logoutSuccessActionMutation.actions,
    ...userUpdateActionMutation.actions,
  },
  mutations: {
    ...loginSuccessActionMutation.mutations,
    ...logoutSuccessActionMutation.mutations,
    ...userUpdateActionMutation.mutations,
  },
};
