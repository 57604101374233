import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { provideApolloClient } from "@vue/apollo-composable";
import { authLink } from "@/api/graphqlClient/authLink";
import { wsHybridLink } from "@/api/graphqlClient/wsLink";
import { config } from "@/shared/utils/config";
import { createUploadLink } from "apollo-upload-client";
import {
  customErrorHandlerLink,
  errorHandlerLink,
} from "@/api/graphqlClient/errorHandlerLink";

/// See documentation of library @see https://v4.apollo.vuejs.org/guide-composable/

export const makeApolloClientInstance = () => {
  const cache = new InMemoryCache();
  const uploadLink = createUploadLink({ uri: config.httpGqlServerUrl });

  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      wsHybridLink,
      errorHandlerLink,
      customErrorHandlerLink,
      uploadLink,
    ]),
    cache,
    defaultOptions: {
      query: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
      },
    },
  });
};

export const apolloProvider = provideApolloClient(makeApolloClientInstance());
