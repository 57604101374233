import { ApolloLink } from "@apollo/client/core";
import { onError } from "apollo-link-error";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import store from "@/web/store";
import useLogging from "@/shared/composables/useLogging";
import { wsClientContainer } from "./wsLink";
import webRouter from "@/web/router";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

/**
 * Update this for to include all [__typename] for all errors that we
 * need to logout the user. For available [__typename] for all errors refer to the schema
 */
const errorTypeNameToLogout = ["AuthenticationError"];

export const customErrorHandlerLink = new ApolloLink((operation, forward) => {
  const { submitInfoToApi } = useLogging();
  return forward(operation).map((data) => {
    const parsedGqlResponse = parseGqlResponse<void>(
      "",
      data?.data,
      HideIfErrorCode.ALL_ERRORS
    );

    if (!isEmpty(parsedGqlResponse?.error?.errors)) {
      /// Automatic logout based on custom error
      const shouldLogout = parsedGqlResponse?.error?.errors?.some((error) =>
        errorTypeNameToLogout.includes(error?.__typename)
      );

      if (shouldLogout && store?.state?.auth?.isAuthenticated) {
        (async () => {
          // close ws client connection
          wsClientContainer.wsClient?.close(true, true);

          await store.dispatch("logoutSuccess");
          await webRouter.push({ name: routeNames.home });
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate("You have been logout due to invalid session")
          );
          submitInfoToApi(
            "SESSION_EXPIRED: Logged Out Automatically due to inactivity"
          );
        })();
      }
    }

    return data;
  });
});

export const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    /**
     * Add console log, this will be available on logrocket
     */
    console.group("errorHandlerLink");
    console.log(`graphQLErrors`, !!graphQLErrors);
    console.log(`networkError`, !!networkError);
    console.log(`errorResponse`, { graphQLErrors, networkError, operation });
    console.groupEnd();

    if (graphQLErrors) {
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("Something went wrong. Please try again later.")
      );
    } else if (networkError) {
      /**
       * If error thrown is this error again
       *
       * Do not proceed if error is "Failed to fetch"
       * - this means that the user do not have internet and logging will also fail
       */
      if (
        (networkError.message && networkError.message === "NETWORK_ERROR") ||
        (networkError.message && networkError.message === "Failed to fetch") ||
        operation.operationName === "createLog"
      ) {
        return;
      }

      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("A network error occurred, Kindly refresh and try again.")
      );
    }
  }
);
