
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { headerRoutes } from "@/web/router";
import { useVModel } from "vue-composable";
import { useRoute } from "vue-router";
import ThemeSwitcher from "../ThemeSwitcher.vue";
import { useAuthenticateViaAvatarada } from "@/shared/composables/api/useAuthentication";
import { useStore } from "vuex";
import Divider from "ant-design-vue/lib/divider";

export default defineComponent({
  components: { ThemeSwitcher, Divider },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["on-drawer-toggle"],
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    const localVisible = useVModel(props, "visible");

    const { handleLogout, logoutLoading } = useAuthenticateViaAvatarada();

    return {
      t,
      localVisible,
      headerRoutes,
      routeHash: computed(() => route.hash),
      isAuthenticated,
      handleLogout,
      logoutLoading,
    };
  },
});
