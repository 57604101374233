import { isEmpty } from "lodash";
import take from "lodash/take";
import takeRight from "lodash/takeRight";

/**
 * Replace all string characters with toReplace char
 *
 * @param str
 * @param toReplace
 * @returns
 */
export const charsReplace = (str: string, toReplace = "*") =>
  str.replace(/./g, toReplace);

export const removeSpaces = (input: string): string => {
  return input ? input.replace(/\s/g, "") : "";
};

export const maskWalletAddress = (walletAddress: string) => {
  if (!walletAddress) return walletAddress;

  const startStr = take(walletAddress, 6).join("");
  const endStr = takeRight(walletAddress, 5).join("");

  return `${startStr}....${endStr}`;
};

export const maskTXHash = (hashAddress: string) => {
  const startStr = take(hashAddress, 8).join("");
  const endStr = takeRight(hashAddress, 9).join("");

  return `${startStr}....${endStr}`;
};

/**
 * Mask IP Address 4
 *
 * @param ip4Address
 * @returns
 *
 * @example
 * maskIp4Address("127.0.0.1")
 * => 127.0.0.*
 */
export const maskIp4Address = (ip4Address: string) => {
  const ip = ip4Address.split(".");
  const lastIndex = ip.length - 1;
  ip[lastIndex] = charsReplace(ip[lastIndex], "*");
  return ip.join(".");
};

/**
 * Mask IP Address 6
 *
 * @param ip6Address
 * @returns
 *
 * @example
 * maskIp6Address("2620:0aba2:0d01:2042:0100:8c4d:d370:72b4")
 * => 2620:0aba2:0d01:2042:****:****:****:****
 */
export const maskIp6Address = (ip6Address: string) => {
  const ip = ip6Address.split(":");
  if (isEmpty(ip)) {
    return ip6Address;
  }

  try {
    const lastAddressCount = Math.floor(ip.length / 2);
    for (
      let nonZeroIndex = 1;
      nonZeroIndex <= lastAddressCount;
      nonZeroIndex++
    ) {
      ip[ip.length - nonZeroIndex] = charsReplace(
        ip[ip.length - nonZeroIndex],
        "*"
      );
    }
    return ip.join(":");
  } catch (error) {
    console.log("Something went wrong when trying to maskIp6Address");
  }

  return ip6Address;
};

/**
 * Mask Port Address
 *
 * @param rawPort
 * @returns
 *
 * @example
 * maskPort("8080")
 * => ***0
 */
export const maskPort = (rawPort: string) => {
  const port = rawPort.split("");
  for (let index = 0; index < port.length - 1; index++) {
    port[index] = charsReplace(port[index]);
  }
  return port?.join("");
};

/**
 * Remove first slash it finds from str parameter
 *
 * @param str
 * @returns
 *
 * @example:
 * removeSlash("/app")
 * => "app"
 */
export const removeSlash = (str: string) => str.replace(/\//, "");

/**
 * Just like removeSlash but this will just remove if slash is start of string
 */
export const removeLeadingSlash = (str: string) => str.replace(/^\//, "");

/**
 * Just like removeSlash but this will just remove if slash is end of string
 */
export const removeTrailingSlash = (str: string) => str.replace(/\/$/, "");
