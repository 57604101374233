import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    type: "primary",
    loading: _ctx.authLoading || _ctx.authenticateViaAvataradaLoading,
    onClick: _ctx.handleLogin
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("Login with avatarada")), 1)
    ]),
    _: 1
  }, 8, ["loading", "onClick"]))
}