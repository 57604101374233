
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useAvataradaAuth } from "@/shared/composables/useAvataradaAuth";
import { useAuthenticateViaAvatarada } from "@/shared/composables/api/useAuthentication";
import { message } from "ant-design-vue";
import { config } from "../utils/config";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { authenticate, authLoading } = useAvataradaAuth();

    const { authenticateViaAvatarada, authenticateViaAvataradaLoading } =
      useAuthenticateViaAvatarada();

    const handleLogin = async () => {
      try {
        /**
         * Handle getting the authorization code from AVATARADA provider.
         */
        const authDetails = await authenticate("AVATARADA");

        /**
         * Handle authenticate via avatarada API
         */
        const response = await authenticateViaAvatarada({
          authorizationCode: authDetails.code,
          redirectUri: config.authAvataradaRedirectUri,
        });

        /**
         * Check if response has auth token ie. successfully authenticate account
         */
        if (response?.authToken) {
          message.info(t("Successfully Login"));
          window.location.reload();
        }
      } catch (error) {
        console.log(
          "LoginWithAvatarada: Something went wrong when trying to authenticate via avatarada",
          error
        );
      }
    };

    return { t, handleLogin, authLoading, authenticateViaAvataradaLoading };
  },
});
