
import { defineComponent, ref } from "vue";
import LayoutFooter from "@/shared/components/Layouts/LayoutFooter.vue";
import LayoutHeader from "@/shared/components/Layouts/LayoutHeader.vue";
import MobileDrawer from "@/shared/components/Layouts/MobileDrawer.vue";

export default defineComponent({
  components: { LayoutHeader, LayoutFooter, MobileDrawer },
  setup() {
    const visible = ref(false); // drawer visible

    const toggleMobileDrawer = (newValue?: boolean) => {
      /**
       * check whether newValue is defined and use it as the new "visible" value
       * note: "!== undefined" is used because newValue can be false
       */
      if (newValue !== undefined) {
        visible.value = newValue;
        return;
      }

      // toggle the value of visible
      visible.value = !visible.value;
    };

    return { visible, toggleMobileDrawer };
  },
});
