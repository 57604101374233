import { getCurrentInstance, ref } from "vue";

export function useAvataradaAuth() {
  /**
   * Vue 3 provides getCurrentInstance() inside the setup() hook. That instance allows access to global properties
   * (installed from plugins) via appContext.config.globalProperties
   *
   * https://stackoverflow.com/questions/66957578/how-to-get-the-this-instance-in-vue-3
   */
  const instance = getCurrentInstance();
  const auth = instance?.appContext?.config?.globalProperties.$auth;

  /**
   * loading state for the current provider being authenticated
   */
  const authLoading = ref(false);

  const authenticate = async (provider) => {
    if (!auth) {
      console.error("VueAuthenticate instance not found!");
    }

    try {
      authLoading.value = true;

      /**
       * try to link the user to the provider
       * this will not try to authenticate the user with the authorization code
       * but will just get the authorization code and save it to the backend
       */
      const response = await auth.link(provider);

      return { provider, ...response };
    } catch (error) {
      console.log("Failed to authenticate", error);
    } finally {
      authLoading.value = false;
    }
  };

  return {
    authenticate,
    authLoading,
  };
}
