import noop from "lodash/noop";
import { config } from "@/shared/utils/config";

// noop is used only to prevent typescript errors
let LogRocket = { init: noop, identify: noop, startNewSession: noop };
let createPlugin;

/**
 * Only add to build if logrocket is enabled
 */
if (config.logRocketEnabled) {
  LogRocket = require("logrocket");
  createPlugin = require("logrocket-vuex");
}

export const logRocketInit = (): void => {
  if (config.logRocketEnabled) {
    LogRocket.init(config.logRocketId);
  }
};

export const logRocketIdentify = (uuid: string): void => {
  if (config.logRocketEnabled && uuid) {
    LogRocket.identify(uuid);
  }
};

export const logRocketNewSession = (): void => {
  if (config.logRocketEnabled) {
    LogRocket.startNewSession();
  }
};

export const logRocketVuexPlugin = config.logRocketEnabled
  ? [createPlugin(LogRocket)]
  : [];
