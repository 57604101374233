import darkTheme from "@/less/dark.json";
import lightTheme from "@/less/light.json";
import { computed, ref, watch } from "vue";

//  eslint-disable-next-line @typescript-eslint/no-explicit-any
const _window: any = window;

// set global theme to light
export const setThemeLight = () => {
  _window.less.modifyVars(lightTheme);
  _window.less.refreshStyles();
};

// set global theme to dark
export const setThemeDark = () => {
  _window.less.modifyVars(darkTheme);
  _window.less.refreshStyles();
};

// set global theme based on params
export const setTheme = (theme: string) => {
  try {
    /**
     * set theme to html's data-theme that
     * will be used on less files with selector
     *
     * @example
     * [data-theme^="dark"] {
     *  ...
     * }
     */
    window.document.documentElement.setAttribute("data-theme", theme);
    if (theme === "dark") {
      setThemeDark();
    } else {
      setThemeLight();
    }
  } catch (error) {
    console.log("Something went wrong when trying to set theme", error);
  }
};

/**
 * composable to easily configure global theme
 * isDarkTheme is a ref so it can be used in a switch
 *
 * isDarkTheme is declared outside of the composable because
 * it doesn't share the same state when imported somewhere else
 * https://stackoverflow.com/questions/69360418/composition-api-global-state-is-not-updating-the-dom
 */
const isDarkTheme = ref(false);
export function useTheme() {
  const toggleTheme = () => {
    isDarkTheme.value = !isDarkTheme.value;
  };

  watch(isDarkTheme, (newIsDarkTheme) => {
    setTheme(newIsDarkTheme ? "dark" : "light");
  });

  return {
    toggleTheme,
    isDarkTheme,
    isLightTheme: computed(() => !isDarkTheme.value),
  };
}
