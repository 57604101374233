import gql from "graphql-tag";

export const authenticateViaAvataradaGql = gql`
  mutation AuthenticateViaAvatarada($input: AuthenticateViaAvataradaInput!) {
    authenticateViaAvatarada(input: $input) {
      ... on Authentication {
        authToken {
          token
          expiry
        }
        user {
          id
          name
          avatarUrl
          username
          isDelegated
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
