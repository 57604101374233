import defaultTo from "lodash/defaultTo";
import toNumber from "lodash/toNumber";
import { removeTrailingSlash } from "@/shared/utils/stringHelper";

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";
const logRocketId = process.env.VUE_APP_LOG_ROCKET_ID || "";
const adaplusUrl = removeTrailingSlash(
  process.env.VUE_APP_ADAPLUS_URL || "https://adaplus.io"
);

export const config = {
  adaplusUrl,
  adaplusBlocksUrl: `${adaplusUrl}/blocks`,
  allowAutomatedTestMode:
    process.env.VUE_APP_ALLOW_AUTOMATED_TEST_MODE === "true",
  httpGqlServerUrl: process.env.VUE_APP_HTTP_GQL_SERVER_URL || "",
  websocketGqlServerUrl: process.env.VUE_APP_WEBSOCKET_GQL_SERVER_URL || "",
  merchantBrand: process.env.VUE_APP_MERCHANT_BRAND || "",
  contactEmail: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_EMAIL || "",

  /**
   * LOCALIZATION AND NUMBER FORMATS
   * for number format convert to number, .env returns string
   */
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  minimumFractionDigits: toNumber(
    defaultTo(process.env.VUE_APP_NUMBER_FORMAT_MINIMUM_FRACTION, 0)
  ),
  maximumFractionDigits: toNumber(
    process.env.VUE_APP_NUMBER_FORMAT_MAXIMUM_FRACTION || 2
  ),

  /**
   * Authenticator Download URL
   */
  authenticatorAppStoreURL: process.env.VUE_APP_APPSTORE_URL || "",
  authenticatorGooglePlayURL: process.env.VUE_APP_GOOGLEPLAY_URL || "",

  /**
   * reCAPTCHA
   */
  recaptchaSiteKey,
  isCaptchaEnabled: !!recaptchaSiteKey,

  /**
   * Logrocket
   *
   * have to check against string "true" because .env uses string type
   * only allow logrocket on development/test environment
   */
  logRocketId,
  logRocketEnabled:
    process.env.VUE_APP_LOG_ROCKET_ENABLE === "true" && !!logRocketId,

  /** static links */
  contactUrl: process.env.VUE_APP_CONTACT_URL,
  privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
  cookiePolicyUrl: process.env.VUE_APP_COOKIE_POLICY_URL,
  termsWebsiteUseUrl: process.env.VUE_APP_TERMS_WEBSITE_USE_URL,
  learnMoreDomainUrl: process.env.VUE_APP_LEARN_MORE_DOMAIN_URL || "#",

  /**
   * social/3rd party authentication
   */
  authBaseUrl: process.env.VUE_APP_AUTH_BASE_URL || "",
  developerBaseUrl: process.env.VUE_APP_DEVELOPER_BASE_URL || "",
  authTelegramBotId: process.env.VUE_APP_AUTH_TELEGRAM_BOT_ID,
  authTelegramDataAuthUrl: process.env.VUE_APP_AUTH_TELEGRAM_DATA_AUTH_URL,
  authTelegramAuthOrigin: process.env.VUE_APP_AUTH_TELEGRAM_AUTH_ORIGIN,
  authInstagramRedirectUri: process.env.VUE_APP_AUTH_INSTAGRAM_REDIRECT_URI,
  authInstagramClientId: process.env.VUE_APP_AUTH_INSTAGRAM_CLIENT_ID,
  authTwitterClientId: process.env.VUE_APP_AUTH_TWITTER_CLIENT_ID,
  authTwitterClientSecret: process.env.VUE_APP_AUTH_TWITTER_CLIENT_SECRET,
  authTwitterRedirectUri: process.env.VUE_APP_AUTH_TWITTER_REDIRECT_URI,
  authDiscordClientId: process.env.VUE_APP_AUTH_DISCORD_CLIENT_ID,
  authDiscordRedirectUri: process.env.VUE_APP_AUTH_DISCORD_REDIRECT_URI,
  authSlackClientId: process.env.VUE_APP_AUTH_SLACK_CLIENT_ID,
  authSlackRedirectUri: process.env.VUE_APP_AUTH_SLACK_REDIRECT_URI,
  authAvataradaClientId: process.env.VUE_APP_AUTH_AVATARADA_CLIENT_ID,
  authAvataradaRedirectUri: process.env.VUE_APP_AUTH_AVATARADA_REDIRECT_URI,
  authAvataradaOauthUrl: process.env.VUE_APP_AUTH_AVATARADA_OAUTH_URL,
};

// Update once we need to export individual widgets/component
export const isFullSite = true;
