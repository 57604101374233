
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrentEpoch } from "../composables/useCurrentEpoch";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { autoDateObject } from "@/shared/utils/date";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { currentEpoch, currentEpochLoading } = useCurrentEpoch();

    return { t, currentEpoch, currentEpochLoading };
  },
  methods: { formatDistanceToNowStrict, autoDateObject },
});
